import { LessonSubmissionResponse, SubmissionResponse, TopicQuestionResponse, TopicSubmissionResponse } from "shared/types/moduleTypes"
import { Dispatch, SetStateAction } from "react"
import { Row, Col } from "react-bootstrap"
import ItemGradeInput from "../components/ItemGradeInput"
import styles from "../submissionTeacher.module.scss"
import CustomHTML from "components/CustomHTML"

interface Props {
    index: number
    responseType: "lesson" | "topic"
    embeddedQuestionCount?: number
    submission: LessonSubmissionResponse | TopicSubmissionResponse
    response: SubmissionResponse | TopicQuestionResponse
    setSubmission: Dispatch<SetStateAction<LessonSubmissionResponse | TopicSubmissionResponse>>
    updateQuestion: any
    sumPoints: (summary: LessonSubmissionResponse | TopicSubmissionResponse) => number
}

const LessonResponseItem = ({ index, responseType, embeddedQuestionCount, submission, response, setSubmission, updateQuestion, sumPoints } : Props) => {

  let questionNumber = ""
  if (responseType === "lesson" && "section" in response) {
    // For embedded questions, changes the index from numbered to lettered (0 -> A, etc.), based on the relative char codes
    // For standalone questions, resets the displayed index to 1 for the first question
    const questionBullet = response.section === 1 ? String.fromCharCode(65 + index) : String(index - embeddedQuestionCount)
    questionNumber = questionBullet
  } else {
    questionNumber = response?.questionNumber?.toString()
  }

  const updateGrade = (value: number) => {
    if (responseType === "lesson" && "section" in response) {
        const newLesson = { ...submission } as LessonSubmissionResponse
        newLesson.responses[index].grade = value
        newLesson.points = sumPoints(newLesson)
        newLesson.grade = (newLesson.points / newLesson.totalPoints) * 100
        setSubmission(newLesson)

        updateQuestion({ section: response.section, questionNumber: response.questionNumber, grade: value })
    } else if (responseType === "topic" && "studentTopicAnswerId" in response) {
        const newTopic = { ...submission } as TopicSubmissionResponse
        newTopic.responses[index].grade = value
        newTopic.points = sumPoints(newTopic)
        newTopic.grade = (newTopic.points / newTopic.totalPoints) * 100
        setSubmission(newTopic)

        updateQuestion({ studentTopicAnswerId: response.studentTopicAnswerId, grade: value })
    }
  }

  const updateComment = (newValue: string) => {
    if (responseType === "lesson" && "section" in response) {
      const newComment = newValue
      if (newComment !== response.comment) {
          void updateQuestion({
              section: response.section,
              questionNumber: response.questionNumber,
              comment: newComment
          })
      }
    } else if (responseType === "topic" && "studentTopicAnswerId" in response) {
        const newComment = newValue
        if (newComment !== response.comment) {
            void updateQuestion({ studentTopicAnswerId: response.studentTopicAnswerId, comment: newComment })
        }
    }
  }

  return (<div className="text-start" key={`${submission.completed} ${index}`}>

      <span className="pe-2">
          <span className="fw-bold">{questionNumber}.</span>
          {responseType === "lesson" ?
              <CustomHTML html={response.question} /> :
              <span dangerouslySetInnerHTML={{ __html: response.question }} />
          }
      </span>

    <br />
    <Row>
        <Col sm={4}>
            <p className="mb-2"><strong>Grade</strong></p>
            <ItemGradeInput
                currentValue={response.grade}
                setValue={(value) => updateGrade(value)}
            />
        </Col>
        <Col sm={8}>
            <p className="mb-2"><strong>Student's Answer</strong></p>
            <p style={{
                minHeight: ""
            }}>
                {response.answer}
                {/*
                    In case the student answer is blanks, we need a none-breaking space 
                    here so the height of the paragraph tag is always rendered.
                    */}
                &nbsp;
            </p>
        </Col>
        <Col sm={12}>
            {/* Only show the correct answer if one exists. A blank box would be confusing */}
            {response.correctAnswer && <>
                <p className="mb-2"><strong>Correct Answer</strong></p>
                <p>
                    <span dangerouslySetInnerHTML={{ __html: response.correctAnswer}} />
                </p>
            </>}
        </Col>
    </Row>
    <Row>
        <Col>
            <p className="mb-2"><strong>Comments</strong></p>
            <textarea
                className={`${styles.commentTextbox} w-100 mb-3 p-2`}
                defaultValue={response.comment ?? ""}
                onBlur={(e) => updateComment(e.currentTarget.value)}
            />
        </Col>
    </Row>
</div>)
}

export default LessonResponseItem
