import { ReactTable, useModel } from "@stem-sims/nexus";
import FAIcon from "components/General/FAIcon";
import ToolTip from "components/General/ToolTip";
import Search from "components/Lessons/Search/Root";
import React from "react";
import { Button, ButtonGroup, Col, Form, Modal, Row, ToggleButton } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { LessonResponse } from "shared/lessons";
import { addPlanToClass, createPlanAssignment, deletePlanAssignment, editLessonPlan, getLessonPlan, IntervalType, IntervalTypes, PlanAssignment, updatePlanAssignment } from "shared/routes/lessonPlans";
import styles from "../teacher.module.scss"
import { Form as FormikForm, Formik, Field } from "formik";
import { getClasses } from "shared/routes/teacher";
import { toast } from "react-toastify";
import { ClassResponse } from "shared/types/teacherTypes";
import { createSearchParams } from "react-router-dom";
import formStyles from "../Forms/forms.module.scss"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes";
import moment from "moment";

type AddPlanType = { classId: string, initialDueDate: string, intervalType: IntervalType, dayInterval: Array<string>, numberInterval: number, viewOption: AssessmentViewOptionsType, allowWeekends: boolean }

export default function LessonPlan({ activeClass }: { activeClass: ClassResponse }) {
    const { planId } = useParams()

    const [modal, setModal] = React.useState<"assignmentSearch" | "deleteAssignment" | "addToClass" | "editPlan">(null)

    const [deleteAssignment, setDeleteAssignment] = React.useState<PlanAssignment>(null)

    const navigate = useNavigate()

    const { response: lessonPlan, refreshModel } = useModel({
        model: getLessonPlan,
        props: { planId },
    })

    const { response: classes } = useModel({
        model: getClasses
    })

    const columns = [
        {
            accessor: "order",
            maxWidth: 10,
            Header: () => (
                <div className="w-100 text-end">#</div>
            )
        },
        {
            Header: "Module Name",
            accessor: "moduleName"
        },
        {
            Header: "Actions",
            Cell: ({ row }: { row: { original: PlanAssignment } }) => {
                return <Row className="mx-0">

                    <Col xs="auto" onClick={() => {
                        navigate(`/dashboard/teacher/plan-assignment/${row.original.lessonPlanAssignmentId}/preview`)
                    }}>
                        <ToolTip title="View Assignment">
                            <Button variant="theme">
                                <FAIcon iconName="glasses" />
                            </Button>
                        </ToolTip>
                    </Col>

                    <Col xs="auto">
                        <Button disabled={row.original.order === 1} onClick={async () => {
                            await updatePlanAssignment({
                                assignmentId: row.original.lessonPlanAssignmentId,
                                order: row.original.order - 1
                            })
                            refreshModel()
                        }}><FAIcon iconName={"caret-up"} /></Button>
                    </Col>

                    <Col xs="auto">
                        <Button disabled={row.original.order === lessonPlan?.assignments?.length} onClick={async () => {
                            await updatePlanAssignment({
                                assignmentId: row.original.lessonPlanAssignmentId,
                                order: row.original.order + 1
                            })
                            refreshModel()
                        }}
                        >
                            <FAIcon iconName={"caret-down"} />
                        </Button>
                    </Col>

                    <Col xs="auto">
                        <Button variant="danger" onClick={() => {
                            setDeleteAssignment(row.original)
                            setModal("deleteAssignment")
                        }}>
                            <span className="d-none d-md-inline">Remove</span>
                            <span className="d-inline d-md-none"><FAIcon iconName="trash-alt" /></span>
                        </Button>
                    </Col>
                </Row>
            }
        },
    ]

    const addPlanInitValues: AddPlanType = {
        classId: "",
        initialDueDate: "",
        intervalType: IntervalTypes[0],
        dayInterval: [],
        numberInterval: 1,
        viewOption: activeClass.studentAssessmentViewOption,
        allowWeekends: false,
    }

    if (!lessonPlan) {
        return null
    }

    return <>
        <Row className="mt-2 ms-2">
            <h1 className="text-start">Lesson Plan {lessonPlan.planName}</h1>
            <h2 className="text-muted text-start">{lessonPlan.description}</h2>
        </Row>

        {lessonPlan.assignments?.some((a) => !a.hasAccess) && <>
            <Row className="mt-2 ms-2">
                Some assignments are not available because of current subscription tier, please upgrade to gain access.
            </Row>
        </>}

        <Row className="mt-2 ms-2">
            <Col className="text-start">
                <Button onClick={() => setModal("addToClass")}>Add To Class</Button>
                <Button className="ms-2" onClick={() => setModal("editPlan")}>Edit Lesson Plan</Button>
            </Col>
        </Row>

        <hr />

        <h3>List of Assignments</h3>
        <div className="d-flex justify-content-end">
            <div>
                <Button className="me-2" variant="theme" onClick={() => setModal("assignmentSearch")}><FAIcon iconName="plus" /> Add Assignment</Button>
                <Button variant="theme" onClick={() => {
                    navigate({
                        pathname: "/books",
                        search: createSearchParams({ lessonPlanId: planId }).toString()
                    })
                }}><FAIcon iconName="plus" /> Add Topic Assignment</Button>
            </div>

        </div>
        <div className="my-4 mx-2">
            <ReactTable
                bulkActions={[]}
                columns={columns}
                customClass={(row: { original: PlanAssignment }) => {
                    return row.original.hasAccess ? "" : styles.unavailableAssignment
                }}
                data={lessonPlan.assignments.map((a) => ({ ...a, id: a.lessonPlanAssignmentId })) ?? []}
            />
        </div>

        <Modal size="xl" show={modal === "assignmentSearch"} onHide={() => setModal(null)}>
            <Modal.Header closeButton>Assignment Search</Modal.Header>

            <Modal.Body>
                <Search
                    onLessonSelection={(lesson: LessonResponse) => {
                        return <>
                            <Button variant="theme float-end me-2" onClick={async () => {
                                await createPlanAssignment({
                                    planId,
                                    assessmentId: lesson.assessment?.id,
                                })
                                setModal(null)
                                refreshModel()
                            }}>Add Assessment</Button>
                            <Button variant="theme float-end me-2" onClick={async () => {
                                await createPlanAssignment({
                                    planId,
                                    assessmentId: lesson.assessment?.id,
                                    lessonId: lesson.id,
                                })
                                setModal(null)
                                refreshModel()
                            }}>Add Lesson</Button>
                        </>
                    }}
                    viewType="landing"
                />
            </Modal.Body>
        </Modal>

        <Modal show={modal === "deleteAssignment"} onHide={() => setModal(null)}>
            <Modal.Header closeButton>Deleting Assignment</Modal.Header>

            <Modal.Body>Are you sure you wish to delete this assignment from the plan?</Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal(null)}>Cancel</Button>
                <Button variant="danger" onClick={async () => {
                    await deletePlanAssignment({ assignmentId: deleteAssignment.lessonPlanAssignmentId })
                    refreshModel()
                    setModal(null)
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>

        <Modal show={modal === "addToClass"} onHide={() => setModal(null)}>
            <Modal.Header closeButton>Add Lesson Plan to Class</Modal.Header>

            <Formik initialValues={addPlanInitValues} onSubmit={async (values: AddPlanType) => {
                if (values.classId === "") {
                    toast.error("Please select a class")
                    return
                }

                if (values.intervalType === "Every X Days" || values.intervalType === "Every X Weeks") {
                    if (typeof values.numberInterval !== "number") {
                        toast.error("Please enter a valid number for the interval")
                        return
                    }

                    if (!values.allowWeekends && [0, 6].includes(new Date(`${values.initialDueDate}Z`).getUTCDay())) {
                        toast.error("Initial Due Date is on a weekend and weekends are not allowed, please change the due date or allow weekends.")
                        return
                    }
                }

                try {
                    await addPlanToClass({
                        planId: planId,
                        classId: values.classId,
                        initialDueDate: values.initialDueDate ? moment(values.initialDueDate).utc().toDate() : undefined,
                        intervalType: values.intervalType,
                        dayInterval: values.dayInterval,
                        numberInterval: values.numberInterval,
                        viewOption: values.viewOption,
                        allowWeekends: values.allowWeekends,
                    })
                } catch (e) {
                    toast.error(e.response?.data?.message ?? "An error has occurred, please try again.")
                    return
                }

                toast.success("Added assignments to class!")
                setModal(null)
            }}>
                {(props) => (
                    <Form as={FormikForm}>
                        <Modal.Body>

                            <Form.Group controlId="classId" className="mb-3 text-start">
                                <Form.Label>Class</Form.Label>
                                <Form.Select name="classId" as={Field} onChange={props.handleChange}>
                                    <option value="">Select a class</option>
                                    {classes?.map((cls) => {
                                        return <>
                                            <option value={cls.id}>{cls.className}</option>
                                        </>
                                    })}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3 text-start" controlId="viewOption">
                                <Form.Label>Assessment View Grade Option</Form.Label>
                                <Form.Select name="viewOption" as={Field} onChange={props.handleChange}>
                                    <option value="Grade">Grade</option>
                                    <option value="Correct/Incorrect">Correct/Incorrect</option>
                                    <option value="Answers">Answers</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3 text-start" controlId="initialDueDate">
                                <Form.Label>Initial Due Date</Form.Label>
                                <Form.Control
                                    as={Field}
                                    type="date"
                                    name="initialDueDate"
                                    max="9999-12-31"
                                />
                            </Form.Group>

                            {props.values.initialDueDate && <>

                                <ButtonGroup className="d-flex justify-content-center mb-2">
                                    {IntervalTypes.map((intervalType) => {
                                        return <ToggleButton
                                            id={intervalType}
                                            type="radio"
                                            name="intervalType"
                                            className={`border-0 fw-bold ${props.values.intervalType === intervalType ? formStyles.termBgSelect : formStyles.termBgUnselect}`}
                                            value={intervalType}
                                            onClick={() => {
                                                props.setFieldValue("intervalType", intervalType)
                                            }}
                                        >
                                            <span>{intervalType}</span>
                                        </ToggleButton>
                                    })}
                                </ButtonGroup>

                                {props.values.intervalType === "Day of Week" &&
                                    <Form.Group className="mb-3 text-start" controlId="dayInterval">
                                        <Form.Check as={Field} type="checkbox" name="dayInterval" label="Monday" value="Monday" id="dayInterval-Monday" />
                                        <Form.Check as={Field} type="checkbox" name="dayInterval" label="Tuesday" value="Tuesday" id="dayInterval-Tuesday" />
                                        <Form.Check as={Field} type="checkbox" name="dayInterval" label="Wednesday" value="Wednesday" id="dayInterval-Wednesday" />
                                        <Form.Check as={Field} type="checkbox" name="dayInterval" label="Thursday" value="Thursday" id="dayInterval-Thursday" />
                                        <Form.Check as={Field} type="checkbox" name="dayInterval" label="Friday" value="Friday" id="dayInterval-Friday" />
                                    </Form.Group>
                                }

                                {(props.values.intervalType === "Every X Days" || props.values.intervalType === "Every X Weeks") && <>
                                    <Form.Group controlId="numberInterval" className="mb-3">
                                        <Form.Label>Interval</Form.Label>
                                        <Form.Control
                                            as={Field}
                                            type="number"
                                            name="numberInterval"
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="allowWeekends" className="text-start">
                                        <Form.Check as={Field} type="checkbox" name="allowWeekends" label="Allow Weekends" />
                                    </Form.Group>
                                </>}

                            </>}

                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="theme" type="submit">Add</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>

        </Modal>

        <Modal show={modal === "editPlan"} onHide={() => setModal(null)}>
            <Modal.Header closeButton>
                Edit Lesson Plan
            </Modal.Header>

            <Formik initialValues={{ name: lessonPlan.planName, description: lessonPlan.description }} onSubmit={async (values) => {
                await editLessonPlan({
                    planId: planId,
                    name: values.name,
                    description: values.description,
                })
                setModal(null)
                refreshModel()
            }}>
                <Form as={FormikForm}>
                    <Modal.Body>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control as={Field} name="name" />
                        </Form.Group>

                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as={Field} name="description" />
                        </Form.Group>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModal(null)}>Cancel</Button>
                        <Button variant="theme" type="submit">Save</Button>
                    </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
    </>
}
