import axios from "axios"

export type LessonPlanResponse = {
    lessonPlanId: string
    username: string
    planName: string
    description: string
}

export interface LessonPlanResponseFull extends LessonPlanResponse {
    assignments: PlanAssignment[]
}

export interface PlanAssignment {
    lessonPlanAssignmentId: string
    lessonPlanId: string
    moduleName: string
    order: number
    lessonId?: string
    assessmentId?: string
    topicId?: string
    topicName?: string
    hasAccess: boolean
}

export const IntervalTypes = ["Day of Week", "Every X Days", "Every X Weeks"] as const
export type IntervalType = typeof IntervalTypes[number]

export async function getLessonPlans(): Promise<LessonPlanResponse[]> {
    const response = await axios.get("/api/lesson-plans")
    return response.data
}

export async function getLessonPlan({ planId }: { planId: string }): Promise<LessonPlanResponseFull> {
    const response = await axios.get(`/api/lesson-plans/${planId}`)
    return response.data
}

export async function deleteLessonPlan({ planId }: { planId: string }): Promise<{ message: string }> {
    const response = await axios.delete(`/api/lesson-plans/${planId}`)
    return response.data
}

export async function createLessonPlan({ name, description }: { name: string, description: string }): Promise<{ id: string, message: string }> {
    const response = await axios.post("/api/lesson-plans", { name, description })
    return response.data
}

export async function editLessonPlan({ planId, name, description }: { planId: string, name?: string, description?: string }) {
    const response = await axios.put(`/api/lesson-plans/${planId}`, { name, description })
    return response.data
}

export async function createPlanAssignment({ planId, topicId, lessonId, assessmentId }: { planId: string, topicId?: string, lessonId?: string, assessmentId?: string }): Promise<{ message: string }> {
    const response = await axios.post("/api/plan-assignments", { planId, topicId, lessonId, assessmentId })
    return response.data
}

export async function addPlanToClass({ planId, intervalType, classId, initialDueDate, dayInterval, numberInterval, viewOption, allowWeekends }: { planId: string, classId: string, intervalType?: IntervalType, initialDueDate?: Date, dayInterval?: Array<string>, numberInterval?: number, viewOption: string, allowWeekends?: boolean }) {
    const response = await axios.post(`/api/lesson-plans/${planId}/add-to-class`, {
        classId,
        initialDueDate,
        intervalType,
        dayInterval,
        numberInterval,
        viewOption,
        allowWeekends,
    })
    return response.data
}

export async function getPlanAssignment({ assignmentId }: { assignmentId: string }): Promise<PlanAssignment> {
    const response = await axios.get(`/api/plan-assignments/${assignmentId}`)
    return response.data
}

export async function deletePlanAssignment({ assignmentId }: { assignmentId: string }) {
    const response = await axios.delete(`/api/plan-assignments/${assignmentId}`)
    return response.data
}

export async function updatePlanAssignment({ assignmentId, order }: { assignmentId: string, order: number }) {
    const response = await axios.put(`/api/plan-assignments/${assignmentId}`, { order })
    return response.data
}


const lessonPlans = {
    getLessonPlans,
    getLessonPlan,
    deleteLessonPlan,
    createLessonPlan,
    createPlanAssignment,
    deletePlanAssignment,
    updatePlanAssignment,
}

export default lessonPlans
