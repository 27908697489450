import axios from "axios"
import { CurriculumBook, CurriculumTopic, CurriculumUnit } from "shared/types/curriculumTypes"
import { Standard } from "shared/types/teacherTypes"

export async function getBooks(): Promise<CurriculumBook[]> {
    const response = await axios.get("/api/curriculum/books")
    return response.data
}

export async function createBook() {
    const response = await axios.post("/api/editing/curriculum/book")
    return response.data
}

export async function updateBook({ bookId, name, description, image, visible, exclusive }: { bookId: string, name?: string, description?: string, image?: string, visible: boolean, exclusive: boolean }) {
    const response = await axios.put("/api/editing/curriculum/book", {
        bookId,
        name,
        description,
        image,
        visible: visible ? "true" : "false",
        exclusive: exclusive ? "true" : "false",
    })
    return response.data
}

export async function getUnits({ bookId }: { bookId: string }): Promise<CurriculumUnit[]> {
    const response = await axios.get("/api/curriculum/units", { params: { bookId } })
    return response.data
}

export async function createUnit({ bookId }: { bookId: string }) {
    const response = await axios.post("/api/editing/curriculum/unit", {
        bookId: bookId,
    })
    return response.data
}

export async function updateUnit({ unitId, name, description, order }: { unitId: string, name?: string, description?: string, order?: number }) {
    const response = await axios.put("/api/editing/curriculum/unit", {
        unitId: unitId,
        name: name,
        description: description,
        order: order,
    })
    return response.data
}

export async function deleteUnit({ unitId }: { unitId: string }) {
    const response = await axios.delete("/api/editing/curriculum/unit", {
        params: {
            unitId: unitId,
        }
    })
    return response.data
}


export async function getTopics({ unitId }: { unitId: string }): Promise<CurriculumTopic[]> {
    const response = await axios.get("/api/curriculum/topics", { params: { unitId } })
    return response.data
}

export async function getTopic({ topicId }: { topicId: string }): Promise<CurriculumTopic> {
    const response = await axios.get("/api/curriculum/topic", { params: { topicId } })
    return response.data
}

export async function createTopic({ unitId }: { unitId: string }) {
    const response = await axios.post("/api/editing/curriculum/topic", {
        unitId: unitId,
    })
    return response.data
}

export async function updateTopic({ topicId, name, description, image, order }: { topicId: string, name?: string, description?: string, image?: string, order?: number }) {
    const response = await axios.put("/api/editing/curriculum/topic", {
        topicId: topicId,
        name: name,
        description: description,
        image: image,
        order: order,
    })
    return response.data
}

export async function deleteTopic({ topicId }: { topicId: string }) {
    const response = await axios.delete("/api/editing/curriculum/topic", {
        params: {
            topicId: topicId,
        }
    })
    return response.data
}

export async function createSection({ topicId }: { topicId: string }) {
    const response = await axios.post("/api/editing/curriculum/section", {
        topicId: topicId,
    })
    return response.data
}

export async function updateSection({ sectionId, header, type, sectionText, lessonId, assessmentId, order }: { sectionId: string, type?: string, header?: string, sectionText?: string, lessonId?: string, assessmentId?: string, order?: number }) {
    const response = await axios.put("/api/editing/curriculum/section", {
        sectionId: sectionId,
        header: header,
        type: type,
        sectionText: sectionText,
        lessonId: lessonId,
        assessmentId: assessmentId,
        order: order,
    })
    return response.data
}

export async function deleteSection({ sectionId }: { sectionId: string }) {
    const response = await axios.delete("/api/editing/curriculum/section", {
        params: {
            sectionId: sectionId,
        }
    })
    return response.data
}

export async function createSectionContent({ sectionId }: { sectionId: string }) {
    const response = await axios.post("/api/editing/curriculum/section-content", {
        sectionId,
    })
    return response.data
}

export async function updateSectionContent({ contentId, content, teacherOnly, order }: { contentId: string, content?: string, teacherOnly?: boolean, order?: number }) {
    const response = await axios.put("/api/editing/curriculum/section-content", {
        contentId,
        content,
        teacherOnly,
        order: order,
    })
    return response.data
}

export async function deleteSectionContent({ contentId }: { contentId: string }) {
    const response = await axios.delete("/api/editing/curriculum/section-content", {
        params: {
            contentId,
        }
    })
    return response.data
}

export async function createSectionQuestion({ sectionId, type }: { sectionId: string, type: string }) {
    const response = await axios.post("/api/editing/curriculum/section-question", {
        sectionId,
        type,
    })
    return response.data
}

export async function updateSectionQuestion(body) {

    const response = await axios.put("/api/editing/curriculum/section-question", body)
    return response.data
}

export async function deleteSectionQuestion({ questionId }: { questionId: string }) {
    const response = await axios.delete("/api/editing/curriculum/section-question", {
        params: {
            questionId,
        }
    })
    return response.data
}

export type Folder = {
    subdirectories: string[]
    files: string[]
}

export async function getImages({ relativePath }: { relativePath: string }): Promise<Folder> {
    const response = await axios.get("/api/curriculum/images", { params: { relativePath } })
    return response.data
}

export async function deleteDrawingImg({ sectionId, questionId, typeToDelete }: { sectionId: string, questionId: string, typeToDelete: "questionImage" | "exampleImage" }): Promise<unknown> {
    const response = await axios.delete(`/api/curriculum/sections/${sectionId}/drawing-images`, {params: { questionId, typeToDelete }})
    return response.data
}

export async function createImageFolder({ directory, newDir }: { directory: string, newDir: string }) {
    const response = await axios.post("/api/curriculum/images", { directory, newDir })
    return response.data
}

export async function uploadImage({ directory, image }: { directory: string, image: File }): Promise<{ message: string, id: string }> {
    const formData = new FormData()
    formData.append("directory", directory)
    formData.append("image", image)
    const response = await axios.post("/api/curriculum/images", formData)
    return response.data
}

export async function getTopicStandards({ topicId }: { topicId: string }): Promise<Standard[]> {
    return axios.get(`/api/topic/${topicId}/standards`).then((response) => response.data)
}

export async function addTopicStandard({ topicId, subtopicId, core }: { topicId: string, subtopicId: string, core: boolean }) {
    return axios.post("/api/editing/topic/standard", {
        topicId: topicId,
        subtopicId: subtopicId,
        core: core,
    })
}

export async function deleteTopicStandard({ id }: { id: string }) {
    return axios.delete("/api/editing/topic/standard", {
        params: {
            id: id,
        }
    })
}

const books = {
    getBooks,
    getUnits,
    getTopics,
    getTopic,
    getImages,
    createImageFolder,
    uploadImage,
    getTopicStandards,
    addTopicStandard,
    deleteTopicStandard,
}

export default books
