import { Dispatch, SetStateAction } from "react"
import { TopicSectionResponse, TopicSubmissionResponse } from "shared/types/moduleTypes"
import LessonResponseItem from "./LessonResponseItem"
import AssessmentResponseItem from "./AssessmentResponseItem"
import useBoolean from "helpers/useBoolean"
import { CurriculumTopic } from "shared/types/curriculumTypes"
import modules from "shared/routes/moduleRoutes"
import DrawingResponseItem from "./DrawingResponseItem"

interface Props {
    topicSubmission: TopicSubmissionResponse
    setTopicSubmission: Dispatch<SetStateAction<any>>
    topic: CurriculumTopic
    studentTopicId: string
    section: TopicSectionResponse
    refresh: () => void
}

export interface UpdateTopicQuestionProps {
    studentTopicAnswerId: string,
    grade?: number,
    comment?: string
}

const TeacherTopicResponses = ({ topicSubmission, setTopicSubmission, topic, section, studentTopicId, refresh } : Props) => {

    const sumTopicPoints = (topicSummary: TopicSubmissionResponse) => {
        return topicSummary.sections.filter(s => s.type === "Questions").map(s => {
            return section.responses?.map(r => r.grade ?? 0).reduce((a, i) => a + i)
        }).reduce((a, i) => a + i)
    }
    const [expand, toggleExpand] = useBoolean(true)

    const updateTopicQuestion = async ({ studentTopicAnswerId, grade = undefined, comment = undefined } : UpdateTopicQuestionProps) => {
        await modules.updateTopicQuestionGrade({ studentTopicAnswerId, studentTopicId, grade, comment })
        return refresh()
    }

  return (
    <div>
        <div className="d-flex justify-content-center">
            <h3 className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>{section.header}</h3>
            <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={toggleExpand} />
        </div>

          {expand && <ol className="p-4">
              {section.responses?.map((response, index) => {
                  if (response.questionType === "Text") {
                      return <LessonResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          index={index}
                          responseType="topic"
                          submission={topicSubmission}
                          response={response}
                          setSubmission={setTopicSubmission}
                          updateQuestion={updateTopicQuestion}
                          sumPoints={sumTopicPoints} />
                  } else if (response.questionType === "MultipleChoice") {
                      return <AssessmentResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          index={index}
                          response={response} />
                  } else {
                      return <DrawingResponseItem
                          key={`${response.studentTopicAnswerId}`}
                          responseType="topic"
                          index={index}
                          response={response}
                          updateQuestion={updateTopicQuestion}
                          submission={topicSubmission}
                          setSubmission={setTopicSubmission}
                          sumPoints={sumTopicPoints} />
                  }
              })}
          </ol>}

    </div>
  )
}

export default TeacherTopicResponses
